// const hosturl = 'https://api.park.aiolia.us';
// const frontOffice = 'https://park.aiolia.us';

// const hosturl = 'http://localhost:3000';
// const frontOffice = 'http://localhost:3001';

// const hosturl = 'http://aiolia.us:3000';
// const frontOffice = 'http://www.aiolia.us:5000';
// const { env } = require("process");

// require('dotenv').config();

const hosturl = process.env.REACT_APP_BACKEND_URL;
// const hosturl = 'http://localhost:3000'
const frontOffice = process.env.REACT_APP_FRONTOFFICE_URL;

const config = {
    host: hosturl,
    frontOffice,
}
export default config;

// user : u.data.user,
//             toSellNew : [Number.parseInt(roomToSell, 10)],
//             toSellAnciens :[others.toSellAnciens] ,
//             activity : chambre

//             toSellNew: [parseInt(roomToSell, 10)],
//             toSellAnciens: [],
//             user,
//             activity: chambre
